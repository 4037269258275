.candidates__details {
	display: grid; 
	align-items: center; 
	gap: 3rem;

	@media screen and (width >=1000px) {
		grid-template-columns: 2fr 1.25fr; 
	}
}

.candidates__img {
	--max-content-width: 80%;
	aspect-ratio: 170 / 150;
	width: 100%;
	height: auto;
	margin-inline: auto;
	order: -1;

	@media screen and (width >= 768px) {
		--max-content-width: 60%;
	}
	
	@media screen and (width >= 1000px) {
		--max-content-width: 100%;
		order: 2;
	}
}

.candidates__list {
	display: grid; 
	gap: 2rem;
}