.u-full-width {
	grid-column: 1 / -1;
}

.u-contain-content {
	max-width: var(--max-content-width);
	margin-inline: auto;
}

.u-flex-center-inline {
	display: flex;
	justify-content: center;
}

.u-contain-text {
	max-width: var(--max-text-width);
	margin-inline: auto;
}

.u-site-padding-inline {
	padding-inline: var(--site-padding-inline);
}

.u-bg-color-brand-1 {
	background-color: var(--c-brand-1);
}

.u-bg-color-brand-2 {
	background-color: var(--c-brand-2);
}

.u-color-white {
	color: #fff;
}

.u-padding-block-10 {
	padding-block: 1rem;
}

.u-padding-20 {
	padding: 2rem 20px;
}

.u-padding-inline-20 {
	padding-inline: 20px;
}

.u-margin-block-start-10 {
	margin-block-start: 1rem;
}

.u-margin-block-start-20 {
	margin-block-start: 2rem;
}

.u-margin-block-start-40 {
	margin-block-start: 4rem;
}

.u-margin-inline-20 {
	margin-inline: 20px;
}

.u-margin-inline-40 {
	margin-inline: 40px;
}

.u-margin-inline-end-25 {
	margin-inline-end: 25px;
}

@media screen and (width >= 768px) {

	.u-padding-40--medium-up {
		padding: 4rem 40px;
	}

	.u-margin-block-start-50--medium-up {
		margin-block-start: 5rem;
	}
	
	.u-margin-block-start-60--medium-up {
		margin-block-start: 6rem;
	}

	.u-margin-inline-40--medium-up {
		margin-inline: 40px;;
	}
	
}

@media screen and (width >= 1000px) {

	.u-padding-80--large-up {
		padding: 8rem 80px;
	}
	
	.u-margin-block-start-60--large-up {
		margin-block-start: 6rem;
	}
	
	.u-margin-block-start-80--large-up {
		margin-block-start: 8rem;
	}

	.u-margin-inline-80--large-up {
		margin-inline: 80px;
	}
	
}














@media screen and (width >= 768px) {

	.u-skewed-bg-medium-up {
		position: relative;
		margin-block: 15vw 12.5vw;
		padding-block-end: 4rem;
	
		&:before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			background-color: inherit;
			transform: skew(0, 5deg);
			top: 0;
			transform-origin: right;
			z-index: -1;
		}
		
		&:after {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			background-color: inherit;
			transform: skew(0, 5deg);
			top: 0;
			transform-origin: top left;
			z-index: -1;
		}
	}
		
}