.footer__content {
	position: relative;
	color: #fff;
	background-color: var(--c-brand-1);
	padding: 5rem 20px 20px 20px;
	text-shadow: 1px 1px #000;

	> *:not(.footer__skyline) {
		isolation: isolate;
	}
}

.footer__list {
	display: grid;
	justify-content: center;
	align-items: center;
	gap: 5rem 50px;
	text-align: center;

	@media screen and (width >= 500px) {
		grid-template-columns: auto auto;
		text-align: left;
	}
}

.footer__sub-list {
	display: grid;
	gap: 1rem;
}

.footer__copyright {
	text-align: center;
	font-size: 1.4rem;
	margin-block-start: 5rem;
}

.footer__attribution {
	text-align: center;
	font-size: 1rem;
}

.footer__wave {
	width: 100%;
	height: auto;
}

.footer__skyline {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: .08;
	object-fit: cover;
}