.partner {
	--max-content-width: 925px;
	display: grid;

	@media screen and (width >= 768px) {
		grid-template-columns: 1fr 1fr;
	}
	
}

.partner__img {
	aspect-ratio: 800 / 1000;
	height: auto;
}

.partner__caption {
	display: grid;
	gap: 2rem;
}

.partner__header {
	display: grid;
	grid-auto-flow: column;
	justify-content: center;
	align-items: center;
	gap: 1.5rem;

	@media screen and (width >= 768px) {
		justify-content: start;
	}
}

.partner__icon {
	translate: 0 .2rem;
}

@media screen and (width >= 768px) {

	.partner--jen picture {
		order: 2;
	}

	.partner__caption {
		align-self: center;
	}

}