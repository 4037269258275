.values {
	display: grid;
	gap: 2rem;
	text-align: center;

	@media screen and (width >= 768px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	
	@media screen and (width >= 1000px) {
		gap: 5rem;
	}
}

.values__header {
	font-size: 1.25em;
}
.values__item {
	display: grid;
	place-content: center;
	aspect-ratio: 1 / .75;
	
	@media screen and (width >= 768px) {
		aspect-ratio: 1 / 1.35;
		place-content: start;
	}
	
	@media screen and (width >= 1000px) {
		aspect-ratio: 1 / 1;
	}
}