@keyframes parallax {
	to {
		background-position: 50% -400%;
	}
}

@keyframes parallax-medium {
	to {
		background-position: 50% -200%;
	}
}

.hero {
	aspect-ratio: 1 / 1.244;
	display: grid;
	gap: 2rem;
	place-content: center;
	text-align: center;
	color: #fff;
	text-shadow: 2px 2px #000;
	background-image: 
		linear-gradient(
			rgba(0, 0, 0, .5), 
			rgba(0, 0, 0, .5)
		),
		image-set(
			url('/src/img/pgh-1440w.webp'),
			url('/src/img/pgh-1440w.jpg')
		);
	background-repeat: no-repeat;
	background-size: auto 150%;
	background-position: 50% 25%;
	animation-name: parallax;
	animation-timing-function: linear;
	animation-timeline: scroll();

	@media screen and (width >= 768px) {
		animation-name: parallax-medium;
		aspect-ratio: 2 / .768;
		background-size: auto 200%;
		background-position: 50% 10%;
		background-image: 
		linear-gradient(
			rgba(0, 0, 0, .5), 
			rgba(0, 0, 0, .5)
		),
		image-set(
			url('/src/img/pgh-2880w.webp'),
			url('/src/img/pgh-2880w.jpg')
		);
	}
}

.hero__company {
	font-size: clamp(3rem, 9vw, 11rem);
	line-height: normal;
	font-weight: normal;
}

.hero__tagline {
	text-wrap: balance;
	font-size: clamp(2.5rem, 5vw, 7rem);
	line-height: normal;
}