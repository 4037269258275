.testimonials {
	display: grid;
	gap: 5rem 50px;

	@media screen and (width >= 1000px) {
		> :last-child {
			grid-column: span 2;
		}
	}
}

.testimonials__quote {
	border-left: 5px solid var(--c-brand-1);
	border-right: 5px solid var(--c-brand-1);
}

.testimonials__author {
	font-style: italic;
	text-align: right;
}