*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
  line-height: 1.5;
}

:where(ul)[role="list"], :where(ol)[role="list"] {
  padding: 0;
  list-style: none;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
  text-wrap: balance;
}

p, figcaption, li {
  text-wrap: pretty;
}

a {
  color: currentColor;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

:target {
  scroll-margin-block: 5ex;
}

@media screen and (prefers-reduced-motion) {
  *, :before, :after {
    transition: none !important;
    animation: none !important;
  }
}

.header {
  display: contents;
}

.logo {
  aspect-ratio: 1;
  height: auto;
  margin: 0 auto;
  transition: width .2s linear;
}

@media screen and (min-width: 375.001px) {
  .logo {
    width: 20rem;
  }
}

.nav {
  --color: var(--c-brand-1);
  --bg-color: var(--site-bg-color);
  color: var(--color);
  background-color: var(--bg-color);
  z-index: 1;
  text-align: center;
  transition: color .2s linear, background-color .2s linear;
  position: sticky;
  top: 0;
}

@media screen and (max-width: 767.999px) {
  .nav {
    font-size: 2.2rem;
  }
}

.nav--is-stuck {
  --color: #fff;
  --bg-color: var(--c-brand-1);
  box-shadow: 0 3px 1px #0000001a;
}

.nav__toggle {
  color: var(--color);
  cursor: pointer;
  background-color: #0000;
  border: 0;
  display: none;
}

:where(.js) .nav__toggle {
  width: 100%;
  display: block;
}

:where(.js) .nav__toggle[aria-expanded="true"] {
  border-bottom: 1px solid;
}

@media screen and (min-width: 768px) {
  :where(.js) .nav__toggle {
    display: none;
  }
}

.nav__menu {
  flex-wrap: wrap;
  place-content: center;
  place-items: center;
  gap: .5rem 10px;
  display: flex;
}

:where(.js) .nav__menu {
  opacity: 0;
  pointer-events: none;
  background-color: var(--bg-color);
  flex-flow: column;
  position: absolute;
  left: 0;
  right: 0;
}

@media screen and (min-width: 768px) {
  :where(.js) .nav__menu {
    opacity: 1;
    pointer-events: auto;
    flex-flow: wrap;
    position: static;
  }
}

.nav__menu--is-active {
  opacity: 1;
  pointer-events: auto;
}

.nav__menu-link {
  padding: .5rem;
  display: block;
  position: relative;
}

.nav__menu-link:after {
  content: "";
  transform-origin: 0 100%;
  background-color: currentColor;
  width: 100%;
  height: 2px;
  transition: transform .25s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.nav__menu-link:hover {
  text-decoration: none;
}

.nav__menu-link:hover:after {
  transform: scaleX(1);
}

@keyframes parallax {
  to {
    background-position: 50% -400%;
  }
}

@keyframes parallax-medium {
  to {
    background-position: 50% -200%;
  }
}

.hero {
  aspect-ratio: 1 / 1.244;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px #000;
  animation-timeline: scroll();
  background-image: -webkit-linear-gradient(#00000080, #00000080), -webkit-image-set(url("pgh-1440w.93518af4.webp") 1x, url("pgh-1440w.afef5072.jpg") 1x);
  background-image: linear-gradient(#00000080, #00000080), image-set("pgh-1440w.93518af4.webp" 1x, "pgh-1440w.afef5072.jpg" 1x);
  background-position: 50% 25%;
  background-repeat: no-repeat;
  background-size: auto 150%;
  place-content: center;
  gap: 2rem;
  animation-name: parallax;
  animation-timing-function: linear;
  display: grid;
}

@media screen and (min-width: 768px) {
  .hero {
    aspect-ratio: 2 / .768;
    background-image: -webkit-linear-gradient(#00000080, #00000080), -webkit-image-set(url("pgh-2880w.179967b2.webp") 1x, url("pgh-2880w.5b07f732.jpg") 1x);
    background-image: linear-gradient(#00000080, #00000080), image-set("pgh-2880w.179967b2.webp" 1x, "pgh-2880w.5b07f732.jpg" 1x);
    background-position: 50% 10%;
    background-size: auto 200%;
    animation-name: parallax-medium;
  }
}

.hero__company {
  font-size: clamp(3rem, 9vw, 11rem);
  font-weight: normal;
  line-height: normal;
}

.hero__tagline {
  text-wrap: balance;
  font-size: clamp(2.5rem, 5vw, 7rem);
  line-height: normal;
}

.partner {
  --max-content-width: 925px;
  display: grid;
}

@media screen and (min-width: 768px) {
  .partner {
    grid-template-columns: 1fr 1fr;
  }
}

.partner__img {
  aspect-ratio: 800 / 1000;
  height: auto;
}

.partner__caption {
  gap: 2rem;
  display: grid;
}

.partner__header {
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .partner__header {
    justify-content: start;
  }
}

.partner__icon {
  translate: 0 .2rem;
}

@media screen and (min-width: 768px) {
  .partner--jen picture {
    order: 2;
  }

  .partner__caption {
    align-self: center;
  }
}

.values {
  text-align: center;
  gap: 2rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .values {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1000px) {
  .values {
    gap: 5rem;
  }
}

.values__header {
  font-size: 1.25em;
}

.values__item {
  aspect-ratio: 1 / .75;
  place-content: center;
  display: grid;
}

@media screen and (min-width: 768px) {
  .values__item {
    aspect-ratio: 1 / 1.35;
    place-content: start;
  }
}

@media screen and (min-width: 1000px) {
  .values__item {
    aspect-ratio: 1;
  }
}

.testimonials {
  gap: 5rem 50px;
  display: grid;
}

@media screen and (min-width: 1000px) {
  .testimonials > :last-child {
    grid-column: span 2;
  }
}

.testimonials__quote {
  border-left: 5px solid var(--c-brand-1);
  border-right: 5px solid var(--c-brand-1);
}

.testimonials__author {
  text-align: right;
  font-style: italic;
}

.employers__details {
  align-items: center;
  gap: 3rem;
  display: grid;
}

@media screen and (min-width: 1000px) {
  .employers__details {
    grid-template-columns: 1.25fr 2fr;
  }
}

.employers__img {
  --max-content-width: 80%;
  aspect-ratio: 161 / 150;
  width: 100%;
  height: auto;
  margin-inline: auto;
}

@media screen and (min-width: 768px) {
  .employers__img {
    --max-content-width: 60%;
  }
}

@media screen and (min-width: 1000px) {
  .employers__img {
    --max-content-width: 100%;
  }
}

.employers__list {
  gap: 2rem;
  display: grid;
}

.candidates__details {
  align-items: center;
  gap: 3rem;
  display: grid;
}

@media screen and (min-width: 1000px) {
  .candidates__details {
    grid-template-columns: 2fr 1.25fr;
  }
}

.candidates__img {
  --max-content-width: 80%;
  aspect-ratio: 170 / 150;
  order: -1;
  width: 100%;
  height: auto;
  margin-inline: auto;
}

@media screen and (min-width: 768px) {
  .candidates__img {
    --max-content-width: 60%;
  }
}

@media screen and (min-width: 1000px) {
  .candidates__img {
    --max-content-width: 100%;
    order: 2;
  }
}

.candidates__list {
  gap: 2rem;
  display: grid;
}

.contact__img {
  aspect-ratio: 600 / 400;
  width: 100%;
  height: auto;
}

.contact__partners {
  text-align: center;
  justify-items: center;
  gap: 4rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .contact__partners {
    grid-template-columns: 1fr 1fr;
    gap: 75px;
  }

  .contact__partners > :first-child {
    margin-inline-start: auto;
  }

  .contact__partners > :last-child {
    margin-inline-end: auto;
  }
}

@media screen and (min-width: 1000px) {
  .contact__partners {
    gap: 100px;
  }
}

.contact__partners-img-container {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  margin-block-end: 1rem;
  overflow: hidden;
}

.contact__partners-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  scale: 1.3;
}

.contact__partners-img--ginny {
  translate: -15px 50px;
}

.contact__partners-img--jen {
  translate: 23px 64px;
  rotate: 13deg;
}

.contact__office-imgs {
  gap: 2rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .contact__office-imgs {
    grid-template-columns: 1fr 1fr;
  }
}

.contact__map {
  aspect-ratio: 16 / 9;
  border: 0;
  width: 100%;
  height: auto;
}

.footer__content {
  color: #fff;
  background-color: var(--c-brand-1);
  text-shadow: 1px 1px #000;
  padding: 5rem 20px 20px;
  position: relative;
}

.footer__content > :not(.footer__skyline) {
  isolation: isolate;
}

.footer__list {
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 5rem 50px;
  display: grid;
}

@media screen and (min-width: 500px) {
  .footer__list {
    text-align: left;
    grid-template-columns: auto auto;
  }
}

.footer__sub-list {
  gap: 1rem;
  display: grid;
}

.footer__copyright {
  text-align: center;
  margin-block-start: 5rem;
  font-size: 1.4rem;
}

.footer__attribution {
  text-align: center;
  font-size: 1rem;
}

.footer__wave {
  width: 100%;
  height: auto;
}

.footer__skyline {
  opacity: .08;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.u-full-width {
  grid-column: 1 / -1;
}

.u-contain-content {
  max-width: var(--max-content-width);
  margin-inline: auto;
}

.u-flex-center-inline {
  justify-content: center;
  display: flex;
}

.u-contain-text {
  max-width: var(--max-text-width);
  margin-inline: auto;
}

.u-site-padding-inline {
  padding-inline: var(--site-padding-inline);
}

.u-bg-color-brand-1 {
  background-color: var(--c-brand-1);
}

.u-bg-color-brand-2 {
  background-color: var(--c-brand-2);
}

.u-color-white {
  color: #fff;
}

.u-padding-block-10 {
  padding-block: 1rem;
}

.u-padding-20 {
  padding: 2rem 20px;
}

.u-padding-inline-20 {
  padding-inline: 20px;
}

.u-margin-block-start-10 {
  margin-block-start: 1rem;
}

.u-margin-block-start-20 {
  margin-block-start: 2rem;
}

.u-margin-block-start-40 {
  margin-block-start: 4rem;
}

.u-margin-inline-20 {
  margin-inline: 20px;
}

.u-margin-inline-40 {
  margin-inline: 40px;
}

.u-margin-inline-end-25 {
  margin-inline-end: 25px;
}

@media screen and (min-width: 768px) {
  .u-padding-40--medium-up {
    padding: 4rem 40px;
  }

  .u-margin-block-start-50--medium-up {
    margin-block-start: 5rem;
  }

  .u-margin-block-start-60--medium-up {
    margin-block-start: 6rem;
  }

  .u-margin-inline-40--medium-up {
    margin-inline: 40px;
  }
}

@media screen and (min-width: 1000px) {
  .u-padding-80--large-up {
    padding: 8rem 80px;
  }

  .u-margin-block-start-60--large-up {
    margin-block-start: 6rem;
  }

  .u-margin-block-start-80--large-up {
    margin-block-start: 8rem;
  }

  .u-margin-inline-80--large-up {
    margin-inline: 80px;
  }
}

@media screen and (min-width: 768px) {
  .u-skewed-bg-medium-up {
    margin-block: 15vw 12.5vw;
    padding-block-end: 4rem;
    position: relative;
  }

  .u-skewed-bg-medium-up:before {
    content: "";
    background-color: inherit;
    transform-origin: 100%;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    transform: skew(0deg, 5deg);
  }

  .u-skewed-bg-medium-up:after {
    content: "";
    background-color: inherit;
    transform-origin: 0 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    transform: skew(0deg, 5deg);
  }
}

:root {
  --c-brand-1: #467056;
  --c-brand-2: #47716c;
  --site-padding-inline: 20px;
  --site-text-color: #000;
  --site-bg-color: #fff;
  --max-content-width: 1100px;
  --max-text-width: 70ch;
}

@media screen and (min-width: 768px) {
  :root {
    --site-padding-inline: 40px;
  }
}

@media screen and (min-width: 1000px) {
  :root {
    --site-padding-inline: 80px;
  }
}

html {
  scroll-behavior: smooth;
  scroll-padding-block-start: 4rem;
  font-size: 62.5%;
}

@media screen and (min-width: 768px) {
  html {
    scroll-padding-block-start: 6rem;
  }
}

body {
  color: var(--site-text-color);
  background-color: var(--site-bg-color);
  grid-template-rows: auto auto 1fr auto;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 2rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  body {
    font-size: 2rem;
  }
}

main {
  grid-template-columns: minmax(var(--site-padding-inline), 1fr) minmax(auto, var(--max-content-width)) minmax(var(--site-padding-inline), 1fr);
  display: grid;
}

main > * {
  grid-column: 2;
}

address {
  font-style: normal;
}

.h2 {
  text-align: center;
  color: var(--c-brand-1);
  margin-block-end: 1em;
  font-size: 4rem;
  font-weight: normal;
}

.h3 {
  font-size: 3rem;
  font-weight: normal;
}

/*# sourceMappingURL=styles.e93753ec.css.map */
