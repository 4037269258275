*, 
*::before, 
*::after {
	box-sizing: border-box;
}

* {
	margin: 0;
}

/* Prevent font size inflation */
html {
	-moz-text-size-adjust: none;
	-webkit-text-size-adjust: none;
	text-size-adjust: none;
}
  
body {
	min-height: 100vh;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
:where(ul)[role='list'],
:where(ol)[role='list'] {
  list-style: none;
  padding: 0;
}

img, 
picture, 
video, 
canvas, 
svg {
	display: block;
	max-width: 100%;
}

input, 
button, 
textarea, 
select {
	font: inherit;
}

p, 
h1, 
h2, 
h3, 
h4, 
h5, 
h6 {
	overflow-wrap: break-word;
	text-wrap: balance;
}

p,
figcaption,
li {
	text-wrap: pretty;
}

a {
	color: currentColor;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
	scroll-margin-block: 5ex;
}
  
@media screen and (prefers-reduced-motion) {
	*,
	*:before,
	*:after {
		animation: none !important;
		transition: none !important;
	}
}