.contact__img {
	aspect-ratio: 600 / 400;
	width: 100%;
	height: auto;
}

.contact__partners {
	display: grid;
	justify-items: center;
	gap: 4rem;
	text-align: center;

	@media screen and (width >= 768px) {
		
		grid-template-columns: 1fr 1fr;
		gap: 75px;

		> :first-child {
			margin-inline-start: auto;
		}
	
		> :last-child {
			margin-inline-end: auto;
		}
			
	}

	@media screen and (width >= 1000px) {
		gap: 100px;
	}
}

.contact__partners-img-container {
	width: 300px;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
	margin-block-end: 1rem;
}

.contact__partners-img {
	scale: 1.3;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.contact__partners-img--ginny {
	translate: -15px 50px;
}

.contact__partners-img--jen {
	rotate: 13deg;
	translate: 23px 64px;
}

.contact__office-imgs {
	display: grid;
	gap: 2rem;

	@media screen and (width >= 768px) {
		grid-template-columns: 1fr 1fr;
	}
}

.contact__map {
	border: 0;
	aspect-ratio: 16 / 9;
	width: 100%;
	height: auto;
}