.nav {
	--color: var(--c-brand-1);
	--bg-color: var(--site-bg-color);
	color: var(--color);
	background-color: var(--bg-color);
	position: sticky;
	top: 0;
	z-index: 1;
	transition: color .2s linear, background-color .2s linear;
	text-align: center;

	@media screen and (width < 768px) {
		font-size: 2.2rem;
	}
}

.nav--is-stuck {
	--color: #fff;
	--bg-color: var(--c-brand-1);
	box-shadow: 0px 3px 1px rgb(0 0 0 / .1);
}

.nav__toggle {
	color: var(--color);
	border: 0;
	background-color: transparent;
	display: none;
	cursor: pointer;
}

:where(.js) .nav__toggle {
	display: block;
	width: 100%;

	&[aria-expanded="true"] {
		border-bottom: 1px solid currentColor;
	}

	@media screen and (width >= 768px) {
		display: none;
	}
}

.nav__menu {
	display: flex;
	flex-wrap: wrap;
	gap: .5rem 10px;
	place-content: center;
	place-items: center;
}

:where(.js) .nav__menu {
	position: absolute;
	left: 0;
	right: 0;
	opacity: 0;
	pointer-events: none;
	flex-direction: column;
	flex-wrap: nowrap;
	background-color: var(--bg-color);

	@media screen and (width >= 768px) {
		position: static;
		flex-wrap: wrap;
		flex-direction: row;
		opacity: 1;
		pointer-events: auto;
	}
}

.nav__menu--is-active {
	opacity: 1;
	pointer-events: auto;
}

.nav__menu-link {
	display: block;
	padding: .5rem;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 2px;
		bottom: 0;
		left: 0;
		background-color: currentColor;
		transform-origin: bottom left;
		transform: scaleX(0);
		transition: transform .25s ease-out;
	}
	
	&:hover {
		text-decoration: none;

		&:after {
			transform: scaleX(1);
		}
	}
}