@import url(reset.css);
@import url(header.css);
@import url(logo.css);
@import url(nav.css);
@import url(hero.css);
@import url(partner.css);
@import url(values.css);
@import url(testimonials.css);
@import url(employers.css);
@import url(candidates.css);
@import url(contact.css);
@import url(footer.css);
@import url(utility.css);

:root {
	--c-brand-1: #467056;
	--c-brand-2: #47716c;

	--site-padding-inline: 20px;
	--site-text-color: #000;
	--site-bg-color: #fff;
	--max-content-width: 1100px;
	--max-text-width: 70ch;

	@media screen and (width >= 768px) {
		--site-padding-inline: 40px;
	}
	
	@media screen and (width >= 1000px) {
		--site-padding-inline: 80px;
	}
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
	scroll-padding-block-start: 4rem;

	@media screen and (width >= 768px) {
		scroll-padding-block-start: 6rem;
	}
}

body {
	font-size: 2rem;
	display: grid;
	grid-template-rows: auto auto 1fr auto;
	color: var(--site-text-color);
	background-color: var(--site-bg-color);
	font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;

	@media screen and (width >= 768px) {
		font-size: 2rem;
	}
}

main {
	display: grid;
	grid-template-columns: 
		minmax(var(--site-padding-inline), 1fr) 
		minmax(auto, var(--max-content-width)) 
		minmax(var(--site-padding-inline), 1fr);

	> * {
		grid-column: 2;
	}
}

address {
	font-style: normal;
}

.h2 {
	text-align: center;
	font-size: 4rem;
	margin-block-end: 1em;
	color: var(--c-brand-1);
	font-weight: normal;
}

.h3 {
	font-size: 3rem;
	font-weight: normal;
}