.employers__details {
	display: grid; 
	align-items: center; 
	gap: 3rem;

	@media screen and (width >=1000px) {
		grid-template-columns: 1.25fr 2fr; 
	}
}

.employers__img {
	--max-content-width: 80%;
	aspect-ratio: 161 / 150;
	width: 100%;
	height: auto;
	margin-inline: auto;

	@media screen and (width >= 768px) {
		--max-content-width: 60%;
	}
	
	@media screen and (width >= 1000px) {
		--max-content-width: 100%;
	}
}

.employers__list {
	display: grid; 
	gap: 2rem;
}